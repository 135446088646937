import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  deleteBannerByIdThunk,
  updateBannerCoverThunk,
  updateBannerThunk,
} from '../../../../redux/slices/banners/thunks'
import { useDispatch } from 'react-redux'

import { BannerTypes } from '../../../../constants/banners'
import Button from '../../../../components/UI/Button'
import DropDown from '../../../../components/UI/DropDown'
import UpdateBannerModal from '../Modals/UpdateBannerModal'
import styles from './styles.module.scss'
import {
  Box,
  Flex,
  Image,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import UploadBannerLocal from '../UploadBannerLocal'
import { colors } from 'shared/style/colors'
import { useMutation } from '@apollo/client'
import {
  GET_BANNERS_ADMIN,
  REMOVE_BANNERS_LOCALE,
} from 'services/banners/graphql'
import AuthAlert from 'screens/Authorization/components/AuthAlert'
import { apolloClient } from 'app/hocs/with-apollo'

const BannerItem = ({
  cover_url,
  banner_type,
  book,
  advertisement,
  banner_id,
  aspectRatio,
  coverUrlTranslates,
}) => {
  const [cover, setCover] = useState(cover_url)
  const [selected, setSelected] = useState('en')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  const [removeBannersFields] = useMutation(REMOVE_BANNERS_LOCALE, {
    refetchQueries: [GET_BANNERS_ADMIN],
  })

  const selectedLocales = useMemo(() => {
    const current = []

    if (cover_url) {
      current.push('en')
    }

    if (coverUrlTranslates) {
      current.push(...Object.keys(coverUrlTranslates))
    }

    return current
  }, [cover_url, coverUrlTranslates])

  const dispatch = useDispatch()

  const [isShowDropDownMenu, setIsShowDropDownMenu] = useState(false)
  const [isShowBannerUpdateModal, setIsShowBannerUpdateModal] = useState(false)

  const handleDropDownToggle = useCallback(() => {
    setIsShowDropDownMenu(!isShowDropDownMenu)
  }, [isShowDropDownMenu])

  const handleBannerUpdateModalShow = useCallback(() => {
    setIsShowDropDownMenu(false)
    setIsShowBannerUpdateModal(true)
  }, [])

  const handleBannerUpdateModalHide = useCallback(() => {
    setIsShowBannerUpdateModal(false)
  }, [])

  const handleDropDownClose = useCallback(() => {
    setIsShowDropDownMenu(false)
  }, [])

  const handleBannerDelete = useCallback(async () => {
    setIsShowDropDownMenu(false)
    const isConfirm = window.confirm('Are you sure?')
    if (isConfirm) {
      dispatch(deleteBannerByIdThunk(banner_id))

      await apolloClient.query({
        query: GET_BANNERS_ADMIN,
        fetchPolicy: 'network-only',
      })
    }
  }, [banner_id, dispatch])

  const handleBannerLocaleDelete = useCallback(async () => {
    setIsShowDropDownMenu(false)
    const isConfirm = window.confirm(`Delete ${selected} locale? Are you sure?`)

    if (isConfirm) {
      try {
        const {
          data: { item },
        } = await removeBannersFields({
          variables: {
            banner: {
              banner_id,
              locale: selected,
            },
          },
        })

        if (item.banner_id === banner_id) {
          toast({
            title: `Banner was succesfully deleted for ${selected}`,
            status: 'success',
            isClosable: true,
            duration: 15000,
            position: 'top',
          })
        } else {
          throw new Error('Failed to upload')
        }

        setSelected('en')
        setCover(cover_url)
      } catch (error) {
        toast({
          position: 'top',
          render: ({ onClose }) => (
            <Box mt='61px'>
              <AuthAlert
                onClose={onClose}
                title='Failed'
                reason={error.message}
              />
            </Box>
          ),
          duration: 5000,
        })
      }
    }
  }, [banner_id, cover_url, removeBannersFields, selected, toast])

  const handleBannerUpdate = useCallback(
    async ({ banner_type, file, attachment, cover_url }) => {
      const banner = {
        banner_id,
        banner_type,
        cover_url,
      }

      if (banner_type === BannerTypes.BOOKS) {
        banner.book = attachment
      }

      if (banner_type === BannerTypes.ADVERTISEMENTS) {
        banner.advertisement = attachment
      }

      await dispatch(updateBannerThunk(banner))
      if (file) {
        await dispatch(updateBannerCoverThunk({ banner_id, formdata: file }))
      }

      await apolloClient.query({
        query: GET_BANNERS_ADMIN,
        fetchPolicy: 'network-only',
      })

      handleBannerUpdateModalHide()
    },
    [banner_id, dispatch, handleBannerUpdateModalHide],
  )

  const handleSelectLocale = useCallback(
    locale => {
      if (selected === locale) {
        return
      }

      setCover(locale === 'en' ? cover_url : coverUrlTranslates[locale])
      setSelected(locale)
    },
    [coverUrlTranslates, cover_url, selected],
  )

  useEffect(() => {
    document.body.addEventListener('scroll', handleDropDownToggle)

    return () => {
      document.body.removeEventListener('scroll', handleDropDownToggle)
    }
  }, [handleDropDownToggle])

  useEffect(() => {
    setCover(cover_url)
  }, [cover_url])

  return (
    <div className={styles.banner}>
      <div className={styles.buttonContainer}>
        <Button onClick={handleDropDownToggle} theme='white'>
          <img src='/images/ellipsis.svg' alt='ellipsis' />
        </Button>
      </div>
      <img
        src={cover || '/images/noimage.jpg'}
        alt='banner-cover'
        className={styles.bannerCover}
      />
      <div className={styles.bannerInfo}>
        <div className={styles.bannerType}>
          <b>Banner type:</b> {banner_type}
        </div>
        {banner_type === BannerTypes.ADVERTISEMENTS && (
          <div className={styles.bannerAdvertisement}>
            <div className={styles.bannerAdvertisementTitle}>
              <b> title: </b> {advertisement.title}
            </div>
            <div className={styles.bannerAdvertisementLink}>
              <b> Link: </b>{' '}
              <a href={advertisement.link} target='_blank' rel='noreferrer'>
                {advertisement.link}
              </a>
            </div>
          </div>
        )}
        {banner_type === BannerTypes.BOOKS && (
          <div>
            <p className={styles.bookTitle}>
              <b>Book title: </b>
              {book.title}
            </p>
            <img
              src={book.cover_link}
              alt='book-cover'
              className={styles.bookCover}
            />
          </div>
        )}

        <Flex w='100%' p='4px' gap='6px' wrap='wrap'>
          {selectedLocales.map(locale => {
            const isSelected = locale === selected
            return (
              <Flex
                key={locale}
                gap='4px'
                onClick={() => handleSelectLocale(locale)}
                p='4px'
                align='center'
                cursor='pointer'
                border={`1px solid ${
                  isSelected ? colors['dark-tone'] : colors.separator
                }`}
                borderRadius='16px'
              >
                <Image
                  w='31px'
                  h='22px'
                  src={`/images/flags/${locale.toLowerCase()}.png`}
                  alt={locale}
                />

                <Text
                  size='poppins18'
                  variant='primary-black-text'
                  fontWeight={400}
                  lineHeight='32px'
                  letterSpacing='-0.018px'
                  maxW='500px'
                  cursor='pointer'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  whiteSpace='nowrap'
                >
                  {locale}
                </Text>
              </Flex>
            )
          })}
        </Flex>
      </div>
      <DropDown
        handleDropDownClose={handleDropDownClose}
        show={isShowDropDownMenu}
        className='banner'
      >
        <DropDown.Option onClick={handleBannerUpdateModalShow}>
          Edit
        </DropDown.Option>
        <DropDown.Option onClick={onOpen}>Upload locale</DropDown.Option>
        {selected !== 'en' && (
          <DropDown.Option
            onClick={handleBannerLocaleDelete}
            className='delete'
          >
            Remove selected locale
          </DropDown.Option>
        )}
        <DropDown.Option onClick={handleBannerDelete} className='delete'>
          Delete
        </DropDown.Option>
      </DropDown>

      {isOpen && (
        <UploadBannerLocal
          onClose={onClose}
          selectedLocales={selectedLocales}
          aspectRatio={aspectRatio}
          banner_id={banner_id}
        />
      )}
      <UpdateBannerModal
        isShow={isShowBannerUpdateModal}
        onHide={handleBannerUpdateModalHide}
        book={book}
        banner_type={banner_type}
        advertisement={advertisement}
        onSubmit={handleBannerUpdate}
      />
    </div>
  )
}

export default BannerItem
