const { gql } = require('@apollo/client')

export const GET_BANNERS = gql`
  query Banners {
    banners(limit: 1000) {
      banner_id
      banner_type
      cover_url
      book {
        title
        book_id
        author {
          first_name
          last_name
        }
      }
      advertisement {
        title
        advertisement_id
        link
      }
    }
  }
`

export const GET_BANNERS_ADMIN = gql`
  query bannerLocales {
    items: bannersWithLocalesAdmin(pagination: { count: 1000, offset: 0 }) {
      banner_id
      banner_type
      aspectRatio
      cover_url
      coverUrlTranslates
      book {
        title
        book_id
        author {
          first_name
          last_name
        }
      }
      advertisement {
        title
        advertisement_id
        link
      }
    }
  }
`

export const UPDATE_BANNERS_LOCALE = gql`
  mutation updateLocalizedBanner($banner: LocaleBannerInput!) {
    item: updateLocalizedBanner(banner: $banner) {
      banner_id
    }
  }
`

export const REMOVE_BANNERS_LOCALE = gql`
  mutation removeLocalizedBanner($banner: RemoveLocaleBannerInput!) {
    item: removeLocalizedBanner(banner: $banner) {
      banner_id
    }
  }
`
